import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import CormiePageContainer from "../layouts/CormiePageContainer"
import PageTitle from "../components/PageTitle"
import SubTitle from "../components/SubTitle"
import CormieSection from "../layouts/CormieSection"
import SearchBar from "../components/SearchBar"
import {
  useAllOrgClientsQuery,
  useLocalUserQuery,
  UserDetailsFragment,
} from "../generated/graphql"
import SearchTags from "../components/SearchTags"
import UsersTable from "../components/UsersTable"
import removeTrailingBackslashes from "../utils/general/removeTrailingBackslashes"
import filterUsers from "../utils/user/filterUsers"
import ToggleAdminLevel from "../components/ToggleAdminLevel"
import isAdminVar from "../apollo/vars/isAdminVar"
import allOrgsVar from "../apollo/vars/allOrgsVar"
import { useReactiveVar } from "@apollo/client"

const CustomerCentre: React.FC = () => {
  const isAdmin = isAdminVar()
  const allOrgs = useReactiveVar(allOrgsVar)

  const [searchTerm, setSearchTerm] = useState<string>("")
  const [localTags, setLocalTags] = useState<Record<string, boolean>>({})
  const [filteredUsers, setFilteredUsers] = useState<UserDetailsFragment[]>()

  const { data: localUserData } = useLocalUserQuery({
    onCompleted: (data) => {
      const routeTags = data?.me?.routeTags
      if (Array.isArray(routeTags) && routeTags.length) {
        const tagObj = localTags
        routeTags.forEach((tag) => {
          if (tag) tagObj[tag] = false
        })
        setLocalTags(tagObj)
      }
    },
  })

  const { data: clientData, loading: clientLoading } = useAllOrgClientsQuery({
    onCompleted: (data) => {
      if (data?.extendedUsers?.length > 0) {
        const tagObj = localTags
        data.extendedUsers.forEach((user) => {
          user?.routeTags.forEach((tag) => {
            if (tag && !Object.keys(localTags).includes(tag)) {
              localTags[tag] = false
            }
          })
        })
        data.getAllRouteTagNamesInPool.forEach((tag) => {
          if (tag && !Object.keys(localTags).includes(tag)) {
            tagObj[tag] = false
          }
        })
        setLocalTags({ ...tagObj })
      }
    },
  })

  useEffect(() => {
    let localUserOrgId = ""
    const localUserOrg = localUserData?.me?.organisation[0]
    if (localUserOrg?.__typename === "organisation_Category") {
      localUserOrgId = localUserOrg.id || ""
    }
    setFilteredUsers(
      filterUsers(
        clientData,
        localTags,
        searchTerm,
        allOrgs,
        localUserOrgId,
      ),
    )
  }, [localTags, searchTerm, clientData, localUserData, allOrgs])

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let str = e.target.value
    if (str.charAt(str.length - 1) === "\\") {
      str = removeTrailingBackslashes(str)
    }
    setSearchTerm(str)
  }

  return (
    <CormiePageContainer>
      <Row className="flex-column flex-md-row">
        <Col>
          <PageTitle>{"Customer centre"}</PageTitle>
          <SubTitle>{"Search for a customer to make an order"}</SubTitle>
        </Col>
        <Col className="d-flex align-items-center justify-content-md-end">
          <Row className="title-links text-center ms-xl-auto">
            <Col className="mb-2">
              <Link to={"/orders"}>
                <p className="me-3 my-0">{"VIEW ORDERS"}</p>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <CormieSection>
        {/* Search Bar component */}
        <SearchBar
          onChange={onSearchInputChange}
          placeholder="Start typing a customer name, tag, phone number or address"
        />
        {/* Search tags component */}
        <SearchTags
          tags={localTags}
          setTags={setLocalTags}
        />
        {/* Admin filter by org toggle */}
        {isAdmin && (
          <ToggleAdminLevel
            ignoreOrgPrompt={"Show only clients from my organisation"}
          />
        )}
        {/* Users table component*/}
        <Row className="my-1 px-sm-3">
          <UsersTable
            usersData={filteredUsers}
            usersLoading={clientLoading}
            userType={"client"}
          />
        </Row>
      </CormieSection>
    </CormiePageContainer>
  )
}

export default CustomerCentre
