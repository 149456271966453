import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import CormiePageContainer from "../layouts/CormiePageContainer"
import PageTitle from "../components/PageTitle"
import CormieSection from "../layouts/CormieSection"
import SearchBar from "../components/SearchBar"
import {
  useAllOrgCarersQuery,
  useLocalUserQuery,
  UserDetailsFragment,
} from "../generated/graphql"
import SearchTags from "../components/SearchTags"
import UsersTable from "../components/UsersTable"
import removeTrailingBackslashes from "../utils/general/removeTrailingBackslashes"
import filterUsers from "../utils/user/filterUsers"
import isAdminVar from "../apollo/vars/isAdminVar"
import ToggleAdminLevel from "../components/ToggleAdminLevel"
import { useReactiveVar } from "@apollo/client"
import allOrgsVar from "../apollo/vars/allOrgsVar"

const AllEmployees: React.FC = () => {
  const isAdmin = isAdminVar()
  const allOrgs = useReactiveVar(allOrgsVar)

  const [searchTerm, setSearchTerm] = useState<string>("")
  const [localTags, setLocalTags] = useState<Record<string, boolean>>({})
  const [filteredUsers, setFilteredUsers] = useState<UserDetailsFragment[]>()

  const { data: localUserData } = useLocalUserQuery()

  const { data: clientData, loading: clientLoading } = useAllOrgCarersQuery({
    onCompleted: (data) => {
      if (data?.extendedUsers?.length > 0) {
        const tagObj: Record<string, boolean> = {}
        data.extendedUsers.forEach((user) => {
          user?.routeTags.forEach((tag) => {
            if (tag && !Object.keys(localTags).includes(tag)) {
              tagObj[tag] = false
            }
          })
        })
        data.getAllRouteTagNamesInPool.forEach((tag) => {
          if (tag && !Object.keys(localTags).includes(tag)) {
            tagObj[tag] = false
          }
        })
        setLocalTags({ ...tagObj })
      }
    },
  })

  useEffect(() => {
    let localUserOrgId = ""
    const localUserOrg = localUserData?.me?.organisation[0]
    if (localUserOrg?.__typename === "organisation_Category") {
      localUserOrgId = localUserOrg.id || ""
    }
    setFilteredUsers(
      filterUsers(
        clientData,
        localTags,
        searchTerm,
        allOrgs,
        localUserOrgId,
      ),
    )
  }, [localTags, searchTerm, clientData, localUserData, allOrgs])

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let str = e.target.value
    if (str.charAt(str.length - 1) === "\\") {
      str = removeTrailingBackslashes(str)
    }
    setSearchTerm(str)
  }

  return (
    <CormiePageContainer>
      <Row className="flex-column flex-md-row">
        <Col>
          <PageTitle>Employees</PageTitle>
        </Col>
      </Row>
      <CormieSection>
        {/* Search Bar component */}
        <SearchBar
          onChange={onSearchInputChange}
          placeholder="Start typing an employee name, tag or phone number"
        />
        {/* Search tags component */}
        <SearchTags
          tags={localTags}
          setTags={setLocalTags}
        />
        {/* Admin filter by org toggle */}
        {isAdmin && (
          <ToggleAdminLevel
            ignoreOrgPrompt={"Show only employees from my organisation"}
          />
        )}
        {/* Users table component*/}
        <Row className="my-1 px-sm-3">
          <UsersTable
            usersData={filteredUsers}
            usersLoading={clientLoading}
            userType="employee"
          />
        </Row>
      </CormieSection>
    </CormiePageContainer>
  )
}

export default AllEmployees
