import { ProductCatalogueQuery } from "../../generated/graphql"

const processSizeData = (data: ProductCatalogueQuery) => {
  // We don't care about stock state, because there is none
  // Everything is unlimited forever yaaaaay

  type SizeDataType = {
    male: { [key: string]: { id: string; size: string }[] }
    female: { [key: string]: { id: string; size: string }[] }
  }

  const sizeData: SizeDataType = {
    male: {},
    female: {},
  }

  const sortingArray = [
    "Small",
    "Medium",
    "Large",
    "X-Large",
    "One Size",
    "Pull-Up Insert",
    "All in One Insert",
  ]

  data?.allProducts?.forEach((product) => {
    if (
      (product?.__typename === "incontinenceProducts_Product" ||
        product?.__typename === "inserts_Product" ||
        product?.__typename === "otherCarersCenterProductType_Product") &&
      product.genders &&
      product.genders.length
    ) {
      product.genders.forEach((gender) => {
        if (gender && product.title) {
          const productGender = gender.toLowerCase() as keyof SizeDataType

          sizeData[productGender][product.title.toLowerCase()] =
            sizeData[productGender][product.title.toLowerCase()] || []

          if (
            product.__typename === "otherCarersCenterProductType_Product" &&
            product?.variants &&
            product?.variants[0] &&
            product?.variants[0].id
          ) {
            sizeData[productGender][product.title.toLowerCase()].push({
              size: product.volume ? `${product.volume}ml` : "One Size",
              id: product.variants[0].id,
            })
          } else if (
            product?.variants &&
            product?.variants[0] &&
            product?.variants[0].id &&
            product?.variants[0].title
          ) {
            sizeData[productGender][product.title.toLowerCase()].push({
              size:
                product.variants[0].title === "One Size" ||
                product.variants[0].title === "All in One Insert" ||
                product.variants[0].title === "Pull-Up Insert" ||
                product.variants[0].title === "Protectors" ||
                product.variants[0].title === "Liners"
                  ? product.volume
                    ? `${product.volume}ml`
                    : "One Size"
                  : product.variants[0].title,
              id: product.variants[0].id,
            })
          }

          sizeData[productGender][product.title.toLowerCase()].sort(
            (
              a: { size: string; id: string },
              b: { size: string; id: string },
            ) => sortingArray.indexOf(a.size) - sortingArray.indexOf(b.size),
          )
        }
      })
    }
  })

  return sizeData
}

export default processSizeData
