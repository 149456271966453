import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { UserDetailsFragment } from "../generated/graphql"
import CormiePillBadge from "./CormiePillBadge"
import Loading from "./Loading"
import PaginationControls from "./PaginationControls"

const UsersTable: React.FC<{
  usersData: UserDetailsFragment[] | undefined
  usersLoading: boolean
  userType: "employee" | "client"
}> = ({ usersData, usersLoading, userType }) => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<UserDetailsFragment[]>()
  const [paginatedUsers, setPaginatedusers] = useState<UserDetailsFragment[]>()
  const [userSort, setUserSort] = useState({ active: true, ascending: true })

  useEffect(() => {
    if (usersData) {
      setUsers(userSort.active ? usersData.sort(compareStrings) : usersData)
    }
  }, [usersData])

  const compareStrings = (a: UserDetailsFragment, b: UserDetailsFragment) => {
    if (a.fullName && b.fullName) {
      if (a.fullName.toLowerCase() === b.fullName.toLowerCase()) return 0
      const comparison = a.fullName.toLowerCase().localeCompare(
        b.fullName.toLowerCase(),
        undefined,
        {numeric: true},
      )

      return userSort.ascending ? comparison : comparison * -1
    }
    return 0
  }

  useEffect(() => {
    if (userSort.active) {
      const sortedUsers = usersData?.sort(compareStrings)
      if (sortedUsers?.length) setUsers([...sortedUsers])
    }
  }, [userSort])

  return (
    <>
      {usersLoading ? (
        <div className="py-5">
          <Loading />
        </div>
      ) : (
        <>
          <Table responsive className="responsive-table">
            <>
              <thead>
                <tr>
                  <th className="pe-3 text-nowrap user-table-name-cell">
                    {userType === "employee" ? "Employee" : "Customer"}
                    <FontAwesomeIcon
                      className={
                        (!userSort.active ? "half-opacity " : "") +
                        "ms-1 cormie-pink-text"
                      }
                      icon={userSort.ascending ? faChevronUp : faChevronDown}
                      onClick={() => {
                        setUserSort({
                          active: true,
                          ascending: !userSort.ascending,
                        })
                      }}
                    />
                  </th>
                  <th className="pe-2 user-table-tag-cell">{"Tags"}</th>
                  <th className="pe-2 user-table-phone-cell">{"Phone"}</th>
                  {userType === "client" && (
                    <th className="user-table-address-cell">{"Address"}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {paginatedUsers?.map((user, i) => {
                  if (user) {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            <a
                              onClick={() => {
                                navigate(`${user?.id}`)
                              }}
                            >
                              <u>{user?.fullName ?? user?.id ?? ""}</u>
                            </a>
                          </td>
                          <td>
                            {user?.routeTags.map((tag, i) => (
                              <CormiePillBadge
                                key={i}
                                blue
                                className="table-tag me-1"
                              >
                                {tag}
                              </CormiePillBadge>
                            ))}
                          </td>
                          <td>
                            {user?.carersCentrePhoneNumber ?? "<please update>"}
                          </td>
                          {userType === "client" && (
                            <td>
                              {(user?.customer?.primaryShippingAddress
                                ?.addressLine1 &&
                                user?.customer?.primaryShippingAddress
                                  ?.locality &&
                                user?.customer?.primaryShippingAddress
                                  ?.addressLine1 +
                                  ", " +
                                  user?.customer?.primaryShippingAddress
                                    ?.locality) ||
                                "<please update>"}
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    )
                  }
                })}
              </tbody>
            </>
          </Table>
          {!users?.length && (
            <div className="mb-2">
              {"No customers found based on your search terms"}
            </div>
          )}
        </>
      )}
      <PaginationControls items={users} setPaginatedItems={setPaginatedusers} />
    </>
  )
}

export default UsersTable
