import React from "react"
import { Col, Row } from "react-bootstrap"
import {
  EditProfileMutationVariables,
  UserDetailsFragment,
} from "../generated/graphql"
import { Field, Form, Formik } from "formik"
import { carerValidationSchema } from "../pages/EditProfile"
import ErrorMessage from "./ErrorMessage"

const CarerUserInformation: React.FC<{
  user: UserDetailsFragment | undefined | null
  isEdit?: boolean
  isCreate?: boolean
  setInputFields?: React.Dispatch<
    React.SetStateAction<
      EditProfileMutationVariables & { routeTags: (string | null)[] }
    >
  >
  inputFields?: EditProfileMutationVariables & { routeTags: (string | null)[] }
}> = ({ user, isEdit, isCreate, setInputFields, inputFields }) => {
  const mutationUpdate = (field: string, value: string) => {
    if (inputFields && setInputFields) {
      let updateState = true
      const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "carersCentrePhoneNumber",
      ]

      const conversionFields: string[] = []

      const removeSpaces: string[] = []

      if (requiredFields.includes(field) && !value) updateState = false

      if (field === "email" && !(value.includes(".") && value.includes("@")))
        updateState = false

      if (removeSpaces.includes(field)) value = value.replaceAll(" ", "")

      if (updateState) {
        setInputFields({
          ...inputFields,
          [field]: conversionFields.includes(field) ? Number(value) : value,
        })
      }
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: inputFields?.firstName || "",
        lastName: inputFields?.lastName || "",
        carersCentrePhoneNumber: inputFields?.carersCentrePhoneNumber || "",
        email: inputFields?.email || "",
      }}
      validationSchema={carerValidationSchema}
      onSubmit={() => {
        null
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          {/* Name */}
          {isEdit ? (
            <>
              <Row className="mapped-profile-field">
                <Col className="text-left d-flex align-items-center col-12 col-sm-4">
                  <span className="muted-text">{"First name"}</span>
                  {isEdit && <span className="text-danger">{"*"}</span>}
                </Col>
                <Col className="text-overflow-wrap profile-input-field text-left">
                  <Field
                    id="firstName"
                    name="firstName"
                    required={true}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.firstName && touched.firstName
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.firstName}
                    touched={touched.firstName}
                  />
                </Col>
              </Row>
              <Row className="mapped-profile-field">
                <Col className="text-left d-flex align-items-center col-12 col-sm-4">
                  <span className="muted-text">{"Last name"}</span>
                  {isEdit && <span className="text-danger">{"*"}</span>}
                </Col>
                <Col className="text-overflow-wrap profile-input-field text-left">
                  <Field
                    id="lastName"
                    name="lastName"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.lastName && touched.lastName
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.lastName}
                    touched={touched.lastName}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Name"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                <>{user?.fullName}</>
              </Col>
            </Row>
          )}
          {/* Phone Number */}
          <Row className="mapped-profile-field">
            <Col className="text-left d-flex align-items-center col-12 col-sm-4">
              <span className="muted-text">{"Phone number"}</span>
              {isEdit && <span className="text-danger">{"*"}</span>}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    id="carersCentrePhoneNumber"
                    name="carersCentrePhoneNumber"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.carersCentrePhoneNumber &&
                      touched.carersCentrePhoneNumber
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.carersCentrePhoneNumber}
                    touched={touched.carersCentrePhoneNumber}
                  />
                </>
              ) : (
                <>{user?.carersCentrePhoneNumber}</>
              )}
            </Col>
          </Row>
          {/* Email */}
          <Row className="mapped-profile-field">
            <Col className="text-left d-flex align-items-center col-12 col-sm-4">
              <span className="muted-text">{"Email address"}</span>
              {isCreate && <span className="text-danger">{"*"}</span>}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    id="email"
                    name="email"
                    readOnly={!isCreate}
                    disabled={!isCreate}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.email && touched.email ? "is-invalid " : "") +
                      (!isCreate ? "text-muted " : "") +
                      "form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.email}
                    touched={touched.email}
                  />
                </>
              ) : (
                <>{user?.email}</>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default CarerUserInformation
