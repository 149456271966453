import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Col, Image, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import {
  faX,
  faCheck,
  faPlus,
  faMinus,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import isCarerVar from "../apollo/vars/isCarerVar"
import CartOrderSummary from "../components/CartOrderSummary"
import Loading from "../components/Loading"
import { useUserProfileQuery } from "../generated/graphql"
import CormiePageContainer from "../layouts/CormiePageContainer"
import PageTitle from "../components/PageTitle"
import productSizesVar from "../apollo/vars/productSizesVar"
import MainButton from "../components/MainButton"
import productCatalogueVar from "../apollo/vars/productCatalogueVar"
import cartVar from "../apollo/vars/cartVar"
import addToCart from "../utils/cart/addToCart"
import removeFromCart from "../utils/cart/removeFromCart"
import { useReactiveVar } from "@apollo/client"
import clearCart from "../utils/cart/clearCart"
import ProductSelectorModal from "../components/ProductSelectorModal"
import useQueryParams from "../hooks/useQueryParams"
import placeholderImage from "../assets/images/cormie_placeholder_image.jpg"
import getGender from "../utils/user/getGender"
import { faCirclePlus, faTrash } from "@fortawesome/pro-regular-svg-icons"
import { getSizeString } from "../utils/product/getSizeString"
import isAdminVar from "../apollo/vars/isAdminVar"
import GenericBoolModal from "../components/GenericBoolModal"

const Cart: React.FC = () => {
  const navigate = useNavigate()
  const productSizes = productSizesVar()
  const catalogue = productCatalogueVar()
  const cart = cartVar()
  const isCarer = isCarerVar()
  const isAdmin = isAdminVar()
  const queryParams = useQueryParams()

  useReactiveVar(cartVar)

  const { data: clientData, loading: clientLoading } = useUserProfileQuery({
    variables: { id: cart.uid ? cart.uid : "0" },
  })

  const [poDeliveryVars, setPoDeliveryVars] = useState({
    optionalPoNumber:
      clientData?.extendedUser?.poNumber &&
      clientData?.extendedUser?.preferredPaymentMethod === "INVOICE"
        ? clientData?.extendedUser?.poNumber
        : "",
    optionalDeliveryInstructions: clientData?.extendedUser?.deliveryInstructions
      ? clientData?.extendedUser?.deliveryInstructions
      : "",
  })

  const [showProductModal, setShowProductModal] = useState<boolean | undefined>(
    !!queryParams.get("selectProduct"),
  )

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const deliveryChars = 52 - (poDeliveryVars?.optionalDeliveryInstructions?.length || 0)

  const handleDeliveryInstructionsChange = (e: React.ChangeEvent<any>) => {
    setPoDeliveryVars({
      ...poDeliveryVars,
      optionalDeliveryInstructions: e.target.value,
    })
  }

  const handlePoChange = (e: React.ChangeEvent<any>) => {
    setPoDeliveryVars({
      ...poDeliveryVars,
      optionalPoNumber: e.target.value,
    })
  }

  return (
    <>
      <GenericBoolModal
        showGenericBoolModal={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false)
        }}
      >
        <div className="mt-3 mx-3">
          <h5 className="mb-3">
            <b>
              {"Are you sure you want to remove all items from the cart?"}
            </b>
          </h5>
          <Row xs={1} sm={2} className="mt-4">
            <Col className="w-auto">
              <MainButton
                className="m-0"
                onClick={() => {
                  if (Object.keys(cart.cart).length > 0) {
                    clearCart()
                  }
                  setShowDeleteModal(false)
                }}
              >
                <b className="px-2">{"REMOVE"}</b>
              </MainButton>
            </Col>
            <Col className="d-flex align-items-center ps-0 ps-sm-5 pt-4 pt-sm-0">
              <a
                onClick={() => {
                  setShowDeleteModal(false)
                }}
              >
                <b>
                  <u>{"CANCEL"}</u>
                </b>
              </a>
            </Col>
          </Row>
        </div>
      </GenericBoolModal>
      <CormiePageContainer>
        <PageTitle>{"Cart"}</PageTitle>
        {clientLoading ||
      !(Object.keys(catalogue).length > 0) ||
      !(Object.keys(productSizes).length > 0) ? (
            <Loading />
          ) : (
            <>
              <Row xxl={2} xl={1} className="">
                <Col xxl={7} className="p-0 pe-xxl-4">
                  <>
                    <Row className="my-3" xs={1} sm={2}>
                      {(isCarer || isAdmin) && (
                        <Col>
                          <a
                            onClick={() => {
                              navigate(
                                cart?.uid
                                  ? `/customers/${clientData?.extendedUser?.id}`
                                  : "/customers",
                              )
                            }}
                            className="cormie-pink-text"
                          >
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              className="me-1"
                            />
                            <u className="d-inline-block">
                              <b>
                                <h5>
                                  {cart?.uid
                                    ? clientData?.extendedUser?.fullName
                                    : "No client selected"}
                                </h5>
                              </b>
                            </u>
                          </a>
                        </Col>
                      )}
                      {cart?.uid && (
                        <Col>
                          <Row className="flex-column flex-sm-row">
                            <Col className="d-flex flex-row mt-3 mt-sm-0 justify-content-start justify-content-sm-end align-items-center">
                              <a>
                                <ProductSelectorModal
                                  show={showProductModal}
                                  setShow={setShowProductModal}
                                  gender={getGender(
                                    clientData?.extendedUser?.userGender,
                                  )}
                                  loading={clientLoading}
                                >
                                  <span className="opacity-75">
                                    {"Add product"}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={faCirclePlus}
                                    className="ms-2 opacity-75"
                                  />
                                </ProductSelectorModal>
                              </a>
                            </Col>
                            <Col className="d-none d-sm-flex flex-row justify-content-end align-items-center">
                              <a
                                className={
                                  Object.keys(cart.cart).length === 0
                                    ? "disabled"
                                    : ""
                                }
                                onClick={() => {
                                  setShowDeleteModal(true)

                                }}
                              >
                                <span
                                  className={
                                    Object.keys(cart.cart).length === 0
                                      ? ""
                                      : "opacity-75"
                                  }
                                >
                                  {"Remove all"}
                                </span>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className={
                                    "ms-2 " +
                                (Object.keys(cart.cart).length === 0
                                  ? ""
                                  : "opacity-75")
                                  }
                                />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    <hr></hr>
                    {Object.keys(cart.cart).length > 0 ? (
                      <>
                        {Object.keys(cart.cart).map((lineItemId, i) => {
                          const lineItem = catalogue[lineItemId]
                          if (
                            (lineItem?.__typename ===
                          "incontinenceProducts_Product" ||
                          lineItem?.__typename === "inserts_Product" ||
                          lineItem?.__typename ===
                            "otherCarersCenterProductType_Product") &&
                        lineItem?.genders &&
                        lineItem.genders[0]
                          ) {
                            const image =
                          lineItem.listingGallery &&
                          lineItem.listingGallery[0]?.url
                            ? lineItem.listingGallery[0].url
                            : placeholderImage
                            const price = lineItem?.variants?.[0]?.price || 0
                            const subtotal = price * cart.cart[lineItemId]

                            return (
                              <React.Fragment key={i}>
                                <Row>
                                  <Col className="d-flex justify-content-end align-items-centre">
                                    <a>
                                      <FontAwesomeIcon
                                        icon={faX}
                                        className="me-2 opacity-50"
                                        data-testid={`removeIcon${i}`}
                                        onClick={() => {
                                          removeFromCart(lineItemId)
                                        }}
                                      />
                                    </a>
                                  </Col>
                                </Row>

                                <Row xs={1} sm={2}>
                                  <Col
                                    sm={3}
                                    className="p-0 pe-2 position-relative col-auto"
                                  >
                                    <Image
                                      src={image}
                                      className="border global-max-height-220"
                                      fluid
                                      rounded
                                    />
                                  </Col>
                                  <Col>
                                    <Row className="mb-2">
                                      <Col>
                                        {lineItem?.title +
                                      (lineItem.volume
                                        ? ` ${lineItem.volume}ml`
                                        : "")}
                                      </Col>
                                    </Row>
                                    <Row>
                                      {getSizeString(lineItem.variants?.[0]?.title)}
                                    </Row>
                                    <Row className="mb-2 size-and-quantity">
                                      <Col>
                                        <span
                                          className="quantity minus"
                                          data-testid={`minus${i}`}
                                          onClick={() => {
                                            if (cart.cart[lineItemId] > 1) {
                                              removeFromCart(lineItemId, 1)
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faMinus} />
                                        </span>
                                        <span className="quantity number">
                                          {cart.cart[lineItemId]}
                                        </span>
                                        <span
                                          className="quantity plus"
                                          data-testid={`plus${i}`}
                                          onClick={() => {
                                            addToCart(lineItemId, 1)
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faPlus} />
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        {lineItem?.packSize && (
                                          <>
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{
                                                color: "#11B8B3",
                                              }}
                                              className="me-2"
                                            />
                                            <b>{`${
                                              lineItem?.packSize *
                                          cart.cart[lineItemId]
                                            } total items`}</b>
                                          </>
                                        )}
                                      </Col>
                                      <Col>
                                        <p className="h5">
                                          <b>{`$${subtotal.toFixed(2)}`}</b>
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                <hr></hr>
                              </React.Fragment>
                            )
                          }
                        })}
                      </>
                    ) : (
                      <p>
                        {
                          "No items in cart. Select a customer to start a new one-off order or manage favourite products."
                        }
                      </p>
                    )}
                    {Object.keys(cart.cart).length > 0 && (
                      <>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center justify-content-start p-0 pe-2 col-auto">
                            <b>{"Payment method:"}</b>
                          </Col>
                          <Col className="d-flex align-items-center justify-content-start p-0 col-auto">
                            <MainButton nonReactive className="m-0">
                              <span className="px-3">
                                {clientData?.extendedUser
                                  ?.preferredPaymentMethod === "CREDIT_CARD"
                                  ? "Credit card"
                                  : "Invoice"}
                              </span>
                            </MainButton>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={12} sm={5} className="p-0 pe-2">
                            <b>{"Purchase order number:"}</b>
                          </Col>
                          <Col xs={12} sm={7} className="p-0">
                            <input
                              data-testid={"poNumberInput"}
                              type="text"
                              onChange={handlePoChange}
                              className="form-control cormie-form-control"
                              defaultValue={
                                clientData?.extendedUser?.poNumber &&
                            clientData?.extendedUser?.preferredPaymentMethod ===
                              "INVOICE"
                                  ? clientData?.extendedUser?.poNumber
                                  : undefined
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col xs={12} sm={5} className="p-0">
                            <b>{"Delivery instructions:"}</b>
                          </Col>
                          <Col xs={12} sm={7} className="p-0 position-relative">
                            <textarea
                              maxLength={52}
                              rows={3}
                              onChange={handleDeliveryInstructionsChange}
                              className="form-control cormie-form-control"
                              defaultValue={
                                clientData?.extendedUser?.deliveryInstructions
                                  ? clientData?.extendedUser?.deliveryInstructions
                                  : ""
                              }
                            />
                            <span
                              style={{
                                bottom: "5px",
                                right: "10px",
                                userSelect: "none",
                                pointerEvents: "none",
                              }}
                              className={"position-absolute half-opacity " + (deliveryChars < 11 ? "text-danger" : "")}
                            >{deliveryChars}</span>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                </Col>
                {Object.keys(cart.cart).length > 0 && (
                  <Col xxl={5} className="p-0">
                    <CartOrderSummary
                      isAddressValid={
                        !!clientData?.extendedUser
                          ?.isAddressSufficientToCompleteOrder
                      }
                      isPaymentDetailsValid={
                        !!clientData?.extendedUser
                          ?.isPaymentInfoSufficientToCompleteOrder
                      }
                      optionalDeliveryInstructions={
                        poDeliveryVars.optionalDeliveryInstructions
                      }
                      optionalPoNumber={poDeliveryVars.optionalPoNumber}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
      </CormiePageContainer>
    </>
  )
}

export default Cart
