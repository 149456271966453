import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import {
  AllOrgOrderDetailsFragment,
  useCancelOrderMutation,
} from "../generated/graphql"
import CormiePillBadge from "./CormiePillBadge"
import GenericBoolModal from "./GenericBoolModal"
import Loading from "./Loading"
import MainButton from "./MainButton"

const CancelOrderPillBadge: React.FC<{
  order: AllOrgOrderDetailsFragment | null
}> = ({ order }) => {
  const [showModal, setShowModal] = useState(false)
  const [cancelOrder, { loading: cancelLoading, error: cancelError }] =
    useCancelOrderMutation()

  return (
    <>
      <GenericBoolModal
        showGenericBoolModal={showModal}
        onHide={() => {
          setShowModal(false)
        }}
      >
        <div className="mt-3 mx-3">
          <h5 className="mb-3">
            <b>
              {"Are you sure you want to cancel this order?"}
            </b>
          </h5>
          <Row xs={1} sm={2} className="mt-4">
            <Col className="w-auto">
              <MainButton
                className="m-0"
                onClick={() => {
                  if (order?.id && !cancelError) {
                    cancelOrder({
                      variables: {
                        id: order.id,
                        refund: true,
                      },
                    })
                  }
                  setShowModal(false)
                }}
              >
                <b className="px-2">{"YES"}</b>
              </MainButton>
            </Col>
            <Col className="d-flex align-items-center ps-0 ps-sm-5 pt-4 pt-sm-0">
              <a
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <b>
                  <u>{"NO"}</u>
                </b>
              </a>
            </Col>
          </Row>
        </div>
      </GenericBoolModal>
      {order && order.id && (
        <CormiePillBadge
          className={!!cancelError || cancelLoading ? "" : "hover-order"}
          pink
          onClick={() => {
            setShowModal(true)
          }}
        >
          <div className="opacity-75">
            {cancelLoading ? (
              <Loading small />
            ) : cancelError ? (
              "Error"
            ) : (
              "Cancel"
            )}
          </div>
        </CormiePillBadge>
      )}
    </>
  )
}

export default CancelOrderPillBadge
