import React from "react"
import { Col, Row } from "react-bootstrap"
import { PageStateType } from "./OrdersTable"

const OrderPaginationControls: React.FC<{
  pageState: PageStateType
  setPageState: React.Dispatch<React.SetStateAction<PageStateType>>
}> = ({ pageState, setPageState }) => {
  const paginationOptions = [25, 50, 75, 100]
  const maxPage = Math.ceil(pageState.max / pageState.show)

  const handleResultNumberChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPageState({
      ...pageState,
      show: Number(event.target.value),
      pageNo: 1,
    })
  }

  return (
    <Row className="mt-1 mb-3 px-3 row flex-column-reverse flex-md-row">
      <div className="result-num-wrapper mx-auto mt-2 mt-md-0">
        <div className="result-num-div">
          <label className="me-2 light-text muted-text">Show</label>
          <select
            className="result-number-input"
            onChange={handleResultNumberChange}
            value={pageState.show}
          >
            {paginationOptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Col className="d-flex justify-content-center align-items-center">
        <div
          className={
            (pageState.pageNo === 1 ? "disabled " : "") +
            "pagination-select previous"
          }
          onClick={() => {
            if (pageState.pageNo > 1) {
              setPageState({
                ...pageState,
                pageNo: pageState.pageNo - 1,
              })
            }
          }}
        >
          <a className="border-right pe-3 user-select-none">{"PREVIOUS"}</a>
        </div>
        <div className="mx-3">
          <span className="d-none d-sm-inline">{"Page"}</span>{" "}
          {pageState.pageNo} of {maxPage}
        </div>
        <div
          className={
            (pageState.pageNo === maxPage ? "disabled " : "") +
            "pagination-select next"
          }
          onClick={() => {
            if (pageState.pageNo < maxPage) {
              setPageState({
                ...pageState,
                pageNo: pageState.pageNo + 1,
              })
            }
          }}
        >
          <a className="border-left ps-3 user-select-none">{"NEXT"}</a>
        </div>
      </Col>
    </Row>
  )
}

export default OrderPaginationControls
