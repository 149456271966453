import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import result from "../generated/graphql"
import logout from "../utils/user/logout"

export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENVIRONMENT === "dev" ? "http://localhost:8000/b65e26c795fa42eb12e5aa85771f708a" : process.env.REACT_APP_GRAPHQL_ENDPOINT,
})

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      if (message === "Unauthorized") {
        // every 401/unauthorized error will be caught here and update the global local state
        localStorage.removeItem("token")
        logout()
      }
    })
  }
})

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: result.possibleTypes,
  }),
  connectToDevTools: process.env.REACT_APP_ENVIRONMENT === "dev",
})

export default client
