import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import isCarerVar from "../apollo/vars/isCarerVar"
import CormiePageContainer from "../layouts/CormiePageContainer"
import PageTitle from "../components/PageTitle"
import UserInformation from "../components/UserInformation"
import {
  EditProfileMutationVariables,
  useCreateNewUserMutation,
  useEditProfileMutation,
  useLocalUserLazyQuery,
  useUserProfileLazyQuery,
  useTriggerRecurringOrderMutation,
  useSetTagsMutation,
  useUserBillingDetailsLazyQuery,
} from "../generated/graphql"
import { useLocation, useNavigate } from "react-router-dom"
import Loading from "../components/Loading"
import CormieSection from "../layouts/CormieSection"
import CormieSectionTitle from "../layouts/CormieSectionTitle"
import UserBillingInformation from "../components/UserBillingInformation"
import UserPermacart from "../components/UserPermacart"
import * as Yup from "yup"
import MainButton from "../components/MainButton"
import CarerUserInformation from "../components/CarerUserInformation"
import EditUserTags from "../components/EditUserTags"
import isAdminVar from "../apollo/vars/isAdminVar"
import client from "../apollo/client"

export const clientValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  userGender: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string().nullable(),
  locality: Yup.string().required("Required"),
  countryCode: Yup.string().typeError("Invalid selection").required("Required"),
  administrativeArea: Yup.string()
    .typeError("Invalid selection")
    .required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
  carersCentrePhoneNumber: Yup.string()
    .matches(/^\s*(\d\s*){8,10}$/, "Invalid format")
    .required("Required"),
  clientId1: Yup.string().nullable(),
  clientId2: Yup.string().nullable(),
  ndisPackageid: Yup.string().nullable(),
  invoiceEmailOverride: Yup.string().email().nullable(),
})

export const carerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  carersCentrePhoneNumber: Yup.string().required("Required"),
})

const carerRequiredFields = [
  "firstName",
  "lastName",
  "email",
  "carersCentrePhoneNumber",
]
const clientRequiredFields = [
  ...carerRequiredFields,
  "userGender",
  "addressLine1",
  "locality",
  "countryCode",
  "administrativeArea",
  "postalCode",
  "carersCentrePhoneNumber",
]

const EditProfile: React.FC<{
  user: "client" | "carer" | "local"
  isCreate?: boolean
}> = ({ user, isCreate }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isCarer = isCarerVar()
  const isAdmin = isAdminVar()
  const isCarerProfile =
    user === "carer" || (user === "local" && (isCarer || isAdmin))

  // Get the UID from the path
  const splitPath = location.pathname.split("/")
  const uid =
    splitPath[
      splitPath.findIndex((val) => {
        switch (user) {
        case "client":
          return val === "customers"
        case "carer":
          return val === "employees"
        }
      }) + 1
    ]

  const [validationError, setValidationError] = useState("")
  const [noPaymentCardError, setNoPaymentCardError] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  const [editProfileMutation, { loading: editMutationLoading }] =
    useEditProfileMutation({
      update(cache, {data}) {
        const userId = cache.identify({
          __typename: "cormieUser",
          id: data?.updateExtendedUser?.id || undefined,
        })

        cache.modify({
          id: userId,
          fields: {
            permacartLineItems(existingLineItems = []) {
              return data?.setPurchasablesInPermacart
            }
          }
        })
      }
    })

  const [createProfileMutation, { loading: createMutationLoading }] =
    useCreateNewUserMutation()

  const [
    userProfileDataQuery,
    { data: clientCarerProfile, loading: clientCarerProfileLoading },
  ] = useUserProfileLazyQuery()

  const [
    localUserDataQuery,
    { data: localProfile, loading: localProfileLoading },
  ] = useLocalUserLazyQuery()

  const [userBillingDetails, { data: userBillingData }] =
    useUserBillingDetailsLazyQuery()

  const [triggerRecurringOrder] = useTriggerRecurringOrderMutation()
  const [setRouteTags] = useSetTagsMutation()

  const userLoading =
    user === "local" ? localProfileLoading : clientCarerProfileLoading
  const userProfile =
    user === "local" ? localProfile?.me : clientCarerProfile?.extendedUser

  const [userProfileMutationVariables, setUserProfileMutationVariables] =
    useState<EditProfileMutationVariables & {
      routeTags: (string | null)[]
        }>({
          userId: uid,
          purchasables: [],
          routeTags: [],
        })

  useEffect(() => {
    if (!isCreate) {
      switch (user) {
      case "carer":
      case "client":
        userProfileDataQuery({
          variables: { id: uid },
          fetchPolicy: "cache-and-network", // Todo, fix cache miss
          onCompleted: (data) => {
            setUserProfileMutationVariables({
              ...data.extendedUser,
              ...data.extendedUser?.customer?.primaryShippingAddress,
              ...userProfileMutationVariables,
            })
          },
        })
        break
      case "local":
        localUserDataQuery({
          fetchPolicy: "cache-and-network",
          onCompleted: (data) => {
            setUserProfileMutationVariables({
              ...data.me,
              ...data.me?.customer?.primaryShippingAddress,
              ...userProfileMutationVariables,
            })
          },
        })
        break
      }
    }
  }, [])

  // If the inputs change and theres a validation error, scrub the error
  useEffect(() => {
    setValidationError("")
  }, [userProfileMutationVariables])

  const correctPlural = (fullName: string) => {
    const firstName = fullName.split(" ")[0]
    if (firstName.slice(-1) === "s") {
      return firstName + "'"
    } else {
      return firstName + "'s"
    }
  }

  const deliveryChars = 52 - (userProfileMutationVariables?.deliveryInstructions?.length || 0)

  const saveUser = async (andNav: boolean) => {
    let validated = false
    try {
      if (user === "carer" || (user === "local" && (isCarer || isAdmin))) {
        await carerValidationSchema.validate({
          ...userProfileMutationVariables,
        })
        validated = true
      } else {
        await clientValidationSchema.validate({
          ...userProfileMutationVariables,
        })
        if (
          userProfile?.id &&
          userProfileMutationVariables?.preferredPaymentMethod !== "INVOICE" &&
          (userProfileMutationVariables?.preferredPaymentMethod ===
            "CREDIT_CARD" ||
            userProfile?.preferredPaymentMethod === "CREDIT_CARD") &&
          userProfile?.customer?.paymentSources &&
          !userProfile?.customer?.paymentSources.length
        ) {
          await userBillingDetails({
            variables: {
              userId: userProfile.id,
            },
          })
          if (
            !userBillingData?.extendedUser?.customer?.paymentSources ||
            (userBillingData?.extendedUser?.customer?.paymentSources &&
              !userBillingData?.extendedUser?.customer?.paymentSources.length)
          ) {
            setNoPaymentCardError(true)
            throw new Error()
          }
        }
        validated = true
      }
    } catch (e: any) {
      // Debug validation error
      // setValidationError(`Validation error: ${e.path}: ${e.errors}`)
      setValidationError(
        "Validation error: please check the outlined fields have content",
      )
      const fieldsToTouch = isCarerProfile
        ? carerRequiredFields
        : clientRequiredFields
      fieldsToTouch.forEach((field) => {
        const input = document.getElementById(field)
        input?.focus({
          preventScroll: true,
        })
        input?.blur()
      })
      const required = document.getElementById("requiredError")
      if (required) {
        required.focus()
        const yOffset = 200
        const y =
          required.getBoundingClientRect().top + window.pageYOffset - yOffset
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    }
    if (validated) {
      if (userProfileMutationVariables.permacartOrderBaseDate) {
        const newBase = new Date(
          userProfileMutationVariables.permacartOrderBaseDate,
        )
        const now = new Date()
        if (
          `${newBase.getMonth()}-${newBase.getDate()}` ===
            `${now.getMonth()}-${now.getDate()}` &&
          userProfile?.id
        ) {
          await triggerRecurringOrder({
            variables: {
              userId: userProfile?.id,
              date: `${newBase.getFullYear()}-${newBase.getMonth()}-${newBase.getDate()}`,
            },
          })
        }
      }
      try {
        if (
          isCreate &&
          userProfileMutationVariables.email &&
          userProfileMutationVariables.firstName &&
          userProfileMutationVariables.lastName
        ) {
          const newUser = await createProfileMutation({
            variables: {
              email: userProfileMutationVariables.email,
              isCarer: user === "carer",
              firstName: userProfileMutationVariables.firstName,
              lastName: userProfileMutationVariables.lastName,
            },
          })
          client.cache.evict({
            id: "ROOT_QUERY",
            fieldName: "extendedUsers",
          })
          client.cache.gc()
          if (
            newUser.data?.addExtendedUser &&
            newUser.data?.addExtendedUser[0] &&
            !newUser.errors &&
            newUser.data?.addExtendedUser[1] === "NEW_USER_CREATED"
          ) {
            const newId = newUser.data.addExtendedUser[0]
            await editProfileMutation({
              variables: {
                ...userProfileMutationVariables,
                userId: newId,
              },
            })
            if (userProfileMutationVariables.routeTags.length > 0) {
              const nonNullTags: string[] = []
              userProfileMutationVariables.routeTags.forEach((tag) => {
                if (tag) nonNullTags.push(tag)
              })
              if (nonNullTags.length > 0) {
                await setRouteTags({
                  variables: { userId: newId, tagNames: nonNullTags },
                })
              }
            }
            if (andNav) {
              navigate(
                `/${(user === "carer" ? "employees/" : "customers/") + newId + (window.location.hash || "")}`,
              )
            } else {
              window.location.reload()
            }
          } else if (
            newUser.data?.addExtendedUser &&
            (newUser.data?.addExtendedUser[1] ===
              "USER_ALREADY_EXISTS_IN_THIS_ORGANISATION" ||
              newUser.data?.addExtendedUser[1] ===
                "USER_ALREADY_EXISTS_IN_ANOTHER_ORGANISATION" ||
              newUser.data?.addExtendedUser[1] ===
                "USER_ALREADY_EXISTS_IN_CARERS_CENTRE_WITH_NO_ORGANISATION")
          ) {
            switch (newUser.data.addExtendedUser[1]) {
            case "USER_ALREADY_EXISTS_IN_ANOTHER_ORGANISATION":
              setValidationError(
                "User with this email already exists in another organisation.",
              )
              break
            case "USER_ALREADY_EXISTS_IN_THIS_ORGANISATION":
              setValidationError(
                "User with this email already exists in your organisation.",
              )
              break
            case "USER_ALREADY_EXISTS_IN_CARERS_CENTRE_WITH_NO_ORGANISATION":
              setValidationError(
                "User with this email already exists on Cormie Ecommerce",
              )
              break
            }
          }
        } else {
          const tags: string[] = []
          if (userProfileMutationVariables.routeTags.length > 0) {
            userProfileMutationVariables.routeTags.forEach((tag) => {
              if (tag && !tags.includes(tag)) tags.push(tag) // tag nullcheck
            })
          }
          await setRouteTags({
            variables: {
              userId:
                user === "local" && userProfile?.id ? userProfile.id : uid,
              tagNames: tags,
            },
          })
          await editProfileMutation({
            variables: {
              ...userProfileMutationVariables,
              userId:
                user === "local" && userProfile?.id ? userProfile.id : uid,
              permacartOrderBaseDate:
                userProfileMutationVariables?.permacartOrderBaseDate &&
                !userProfileMutationVariables.permacartOrderDayInterval
                  ? null
                  : userProfileMutationVariables?.permacartOrderBaseDate,
            },

          })
          let navPath = "/"
          switch (user) {
          case "carer":
            navPath = `/employees/${userProfile?.id}${window.location.hash}`
            break
          case "client":
            navPath = `/customers/${userProfile?.id}${window.location.hash}`
            break
          case "local":
            navPath = "/profile"
            break
          default:
            break
          }
          navigate(navPath)
        }
      } catch (e: any) {
        setValidationError("Processing error: Check your inputs and try again.")
      }
    }
  }

  // Scroll to anchor link if applicable
  const { hash } = window.location
  if (hash != "" && !hasScrolled && userProfile && userProfileMutationVariables.firstName) {
    setTimeout(() => {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView()
        // Full page rendering doesn't technically complete until
        // lazy loaded components finish loading. However, if we wait
        // for those components before we scroll the page feels weird
        // slash sluggish. We instead let the scroll hook fire as many
        // times as it wants for two seconds, then disable it. Otherwise,
        // future re-renders will cause the page to scroll again
        setTimeout(() => {
          if (!hasScrolled) setHasScrolled(true)
        }, 2000)
      }
    }, 0)
  }

  return (
    <CormiePageContainer>
      {/* This loading condition is hacky... but it works... */}
      {(!isCreate && (!userProfile || !userProfileMutationVariables.firstName)) ? (
        <Loading className="mt-5" />
      ) : (
        <>
          {/* User Details Row */}
          <Row>
            <Col>
              <PageTitle className="mb-5">
                {user === "local" ? (
                  <>{"Update details"}</>
                ) : (
                  <>{`${isCreate ? "Create" : "Edit"} ${
                    user === "carer" ? "Employee" : user
                  }
                  `}</>
                )}
              </PageTitle>
            </Col>
          </Row>
          <Row xs={1} xxl={2}>
            <Col>
              <CormieSection left>
                <CormieSectionTitle>
                  <span id="userInformation">
                    {`${
                      user === "client"
                        ? "Client"
                        : user === "carer"
                          ? "Employee"
                          : "Your"
                    } information`}
                  </span>
                </CormieSectionTitle>
                {isCarerProfile ? (
                  <CarerUserInformation
                    user={userProfile}
                    isEdit={true}
                    isCreate={isCreate}
                    inputFields={userProfileMutationVariables}
                    setInputFields={setUserProfileMutationVariables}
                  />
                ) : (
                  <UserInformation
                    user={userProfile}
                    isEdit={true}
                    isCreate={isCreate}
                    inputFields={userProfileMutationVariables}
                    setInputFields={setUserProfileMutationVariables}
                  />
                )}
                <></>
              </CormieSection>
            </Col>
            <Col>
              <Col>
                {(isCarer || isAdmin) && (
                  <>
                    <CormieSection right>
                      <CormieSectionTitle>
                        <span id="userTags">
                          {`${
                            user === "client"
                              ? "Client"
                              : user === "carer"
                                ? "Employee"
                                : "Your"
                          } tags`}
                        </span>
                      </CormieSectionTitle>
                      <EditUserTags
                        userProfile={userProfile}
                        userMutationState={userProfileMutationVariables}
                        setUserMutationState={setUserProfileMutationVariables}
                      />
                    </CormieSection>
                  </>
                )}

                {(user === "client" ||
                  user === "local" &&
                  !(isCarer || isAdmin)
                ) && (
                  <CormieSection right>
                    <CormieSectionTitle>
                      <span id="deliveryInstructions">
                        {"Delivery instructions"}
                      </span>
                    </CormieSectionTitle>
                    <Row
                      className="position-relative"
                    >
                      <Form.Control
                        maxLength={52}
                        as="textarea"
                        rows={3}
                        defaultValue={
                          userProfile?.deliveryInstructions
                            ? userProfile?.deliveryInstructions
                            : ""
                        }
                        onChange={(e) => {
                          setUserProfileMutationVariables({
                            ...userProfileMutationVariables,
                            deliveryInstructions: e.target.value,
                          })
                        }}
                      />
                      <span
                        style={{
                          bottom: "5px",
                          textAlign: "right",
                          userSelect: "none",
                          pointerEvents: "none",
                        }}
                        className={"position-absolute half-opacity " + (deliveryChars < 11 ? "text-danger" : "")}
                      >{deliveryChars}</span>
                    </Row>
                  </CormieSection>
                )}
              </Col>
            </Col>
          </Row>
          {(user === "client" ||
            user === "local" &&
            !(isCarer || isAdmin)
          ) && (
            <>
              <Row className="justify-content-between mt-5">
                <Col>
                  <h1 id="permacart">
                    {(user === "local"
                      ? "Your"
                      : userProfileMutationVariables?.firstName
                        ? correctPlural(userProfileMutationVariables.firstName)
                        : ""
                    )
                    + (userProfileMutationVariables?.firstName ? " f" : "F")
                    + "avourite products"}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CormieSection>
                    <UserPermacart
                      permacartLineItems={userProfile?.permacartLineItems}
                      permacartLoading={userLoading}
                      permacartOrderDayInterval={
                        userProfile?.permacartOrderDayInterval
                      }
                      permacartTriggersInNDays={
                        userProfile?.permacartTriggersInNDays
                      }
                      user={userProfile}
                      userType={user}
                      isEdit
                      userProfileState={userProfileMutationVariables}
                      setUserProfileState={setUserProfileMutationVariables}
                    />
                  </CormieSection>
                </Col>
              </Row>
              {/* User billing row */}
              {(
                !isCreate &&
                (user === "client" ||
                !(isCarer || isAdmin))
              ) && (
                <>
                  <Row className="mt-5">
                    <Col>
                      <h1 id="userBilling">{"Billing"}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CormieSection
                        className={
                          noPaymentCardError ? "is-invalid-manual " : ""
                        }
                      >
                        <UserBillingInformation
                          user={userProfile}
                          isEdit
                          userProfileMutationState={
                            userProfileMutationVariables
                          }
                          setUserProfileMutationState={
                            setUserProfileMutationVariables
                          }
                          noPaymentCardError={noPaymentCardError}
                          setNoPaymentCardError={setNoPaymentCardError}
                        />
                      </CormieSection>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {validationError && (
            <Row className="mb-1">
              <Col className={"text-center"} style={{ color: "red" }}>
                {validationError}
              </Col>
            </Row>
          )}
          <Row className="floating-button-set">
            <Col className="col-auto d-flex align-items-center ps-0 pe-2">
              <MainButton
                color="blue"
                className="m-0"
                onClick={() => {
                  saveUser(true)
                }}
                disabled={editMutationLoading || createMutationLoading}
              >
                {editMutationLoading || createMutationLoading ? (
                  <Loading />
                ) : isCreate ? (
                  <>{`Save new ${isCarerProfile ? "employee" : "client"}`}</>
                ) : (
                  "Update details"
                )}
              </MainButton>
            </Col>
            {isCreate && !(editMutationLoading || createMutationLoading) && (
              <Col className="col-auto hide-on-mobile d-flex align-items-center">
                <MainButton
                  color="pink"
                  className="m-0 hide-on-mobile"
                  invert
                  onClick={() => {
                    saveUser(false)
                  }}
                  disabled={editMutationLoading || createMutationLoading}
                >
                  <>
                    {`Save and add another ${
                      isCarerProfile ? "employee" : "client"
                    }`}
                  </>
                </MainButton>
              </Col>
            )}
            {!(editMutationLoading || createMutationLoading) && (
              <Col className="d-flex align-items-center ps-4 ps-md-5 pt-md-2 pt-sm-0 col-auto">
                <a
                  onClick={() => {
                    if (isCreate) {
                      navigate("/customers")
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  <b>
                    <u>{"CANCEL"}</u>
                  </b>
                </a>
              </Col>
            )}
          </Row>
          <div className="floating-button-set-buffer" />
        </>
      )}
    </CormiePageContainer>
  )
}

export default EditProfile
