import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import {
  faCheck,
  faChevronLeft,
  faMars,
  faMinus,
  faPlus,
  faSquareFull,
  faVenus,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import productSizesVar from "../apollo/vars/productSizesVar"
import productCatalogueVar from "../apollo/vars/productCatalogueVar"
import MainButton from "./MainButton"
import addToCart from "../utils/cart/addToCart"
import Volume from "./Volume"
import productCategoryVar from "../apollo/vars/productCategoryVar"
import { CategoryDetailsFragment } from "../generated/graphql"
import Loading from "./Loading"
import placeholderImage from "../assets/images/cormie_placeholder_image.jpg"
import cartVar from "../apollo/vars/cartVar"
import removeFromCart from "../utils/cart/removeFromCart"

const ProductSelectorModal: React.FC<{
  show: boolean | undefined
  setShow: React.Dispatch<React.SetStateAction<boolean | undefined>>
  children?: React.ReactNode
  context?: "cart" | "preferences"
  gender?: "male" | "female"
  loading?: boolean
  callback?: () => void
  preferencesState?: { id: string; qty: number }[]
  updatePreferencesState?: React.Dispatch<
    React.SetStateAction<{ id: string; qty: number }[] | undefined>
  >
}> = ({
  show,
  setShow,
  children,
  context = "cart",
  gender,
  callback,
  loading = false,
  preferencesState,
  updatePreferencesState,
}) => {
  type Styles =
    | "liners"
    | "pull-up pants"
    | "all-in-one"
    | "protectors"
    | "other"
  type Genders = "male" | "female"

  const [step, setStep] = useState<number>(gender ? 2 : 1)
  const [selection, setSelection] = useState<{
    gender?: Genders
    qty?: number
    style?: Styles
  }>({ gender: gender })

  const productSizes = productSizesVar()
  const productCatalogue = productCatalogueVar()
  const productCategories = productCategoryVar()
  const cart = cartVar()

  const [productQty, setProductQty] = useState<{ [key: string]: number }>({})

  useEffect(() => {
    const productIds: { [key: string]: number } = {}

    for (const [_, value] of Object.entries(productCatalogue)) {
      if (typeof value.id === "string") {
        const productId = parseInt(value.id, 10) + 1
        if (!isNaN(productId)) {
          productIds[productId] = 0
        }
      }

      setProductQty({ ...productQty, ...productIds })
    }
  }, [])

  const insertMappings = {
    "all-in-one": "all in one insert",
    "pull-up pants": "pull-up insert",
  }

  const noSize = ["All in One Insert", "One Size", "Pull-Up Insert"]

  function isValidStyle(value: string): value is Styles {
    return [
      "liners",
      "pull-up pants",
      "all-in-one",
      "protectors",
      "other",
    ].includes(value)
  }

  function isValidGender(value: string): value is Genders {
    return ["male", "female"].includes(value)
  }

  const mainProducts = [
    "liners",
    "pull-up pants",
    "all-in-one",
    "protectors",
    "all in one insert",
    "pull-up insert",
  ]

  const getOtherProducts = () => {
    const finalArr: any[] = []
    if (selection?.gender) {
      Object.entries(productSizes[selection.gender]).forEach((kv) => {
        if (!mainProducts.includes(kv[0]) && Array.isArray(kv[1])) {
          finalArr.push(kv[1][0])
        }
      })
    }
    finalArr.sort((a, b) => {
      const product1 = productCatalogue[a.id]
      const product2 = productCatalogue[b.id]
      if (
        product1.__typename === "otherCarersCenterProductType_Product" &&
        product2.__typename === "otherCarersCenterProductType_Product"
      ) {
        if (!product1?.productSortOrder && product2?.productSortOrder) return 1
        if (!product2?.productSortOrder && product1?.productSortOrder) return -1
        return product1?.productSortOrder - product2?.productSortOrder
      } else {
        // products that aren't in the "other" category get sorted last
        return -1
      }
    })
    return finalArr
  }

  const isAlreadyAdded = (id: string) => {
    if (context === "preferences") {
      return preferencesState?.map((obj) => obj.id).includes(id)
    } else {
      return Object.keys(cart.cart).includes(id)
    }
  }

  const getSetProductCount = (id: string) => {
    if (context === "preferences" && preferencesState) {
      const itemIndex = preferencesState.map((obj) => obj.id).indexOf(id)
      return preferencesState[itemIndex].qty
    } else {
      return cart.cart[id]
    }
  }

  const handleBack = () => {
    switch (step) {
    case 2:
      setSelection({})
      break
    case 3:
      setSelection({
        gender: selection.gender,
      })
      break
    }
    setStep(step - 1)
  }

  const handleClose = () => {
    setShow(false)
    setTimeout(() => { // wait for the modal to transition out
      setSelection(gender ? {gender: gender} : {})
      setStep(gender ? 2 : 1)
    }, 500)
  }

  const handleShow = () => {
    setShow(true)
  }

  const renderFeature = (i?: number, feature?: string | null) => {
    if (feature) {
      return (
        <p className="mb-0" key={i}>
          <FontAwesomeIcon icon={faCheck} color={"green"} className={"me-2"} />
          <b>{feature}</b>
        </p>
      )
    }
  }

  const renderGenderCard = (gender: string, i: number) => {
    return (
      <Col key={i}>
        <Card
          className="mx-4 my-3 product-modal-card"
          onClick={() => {
            setSelection({
              gender: isValidGender(gender) ? gender : undefined,
            })
            setStep(2)
          }}
        >
          <Card.Header
            style={{
              backgroundColor: "white",
            }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={gender === "male" ? faMars : faVenus}
                mask={faSquareFull}
                className={
                  gender === "male" ? "blue-gradient-icon" : "red-gradient-icon"
                }
                inverse
                style={{
                  fontSize: "10rem",
                }}
              />
            </div>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-center align-items-center">
              <h5>
                <b>{gender.charAt(0).toUpperCase() + gender.slice(1)}</b>
              </h5>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }

  const renderCategoryCard = (category: CategoryDetailsFragment, i: number) => {
    if (
      category.__typename === "productType_Category" &&
      category.categoryShortTitle &&
      category.listingGallery &&
      category.listingGallery[0]
    ) {
      const styleSelection = category.categoryShortTitle.toLowerCase()
      const imageUrl = category.listingGallery[0].url
        ? category.listingGallery[0].url
        : "Backup URL"
      return (
        <Col className="d-flex align-items-stretch" key={i}>
          <Card
            className="mx-2 my-3 product-modal-card"
            onClick={() => {
              setSelection({
                ...selection,
                style: isValidStyle(styleSelection)
                  ? styleSelection
                  : undefined,
              })
              setStep(3)
            }}
          >
            <Card.Img src={imageUrl} className="p-2" />
            <Card.Body>
              <div className="d-flex justify-content-center align-items-start flex-column">
                <h5>
                  <b>{category.categoryShortTitle}</b>
                </h5>
                <p>{category.description}</p>
                {category.features?.map((feat, i) =>
                  renderFeature(i, feat?.feature),
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      )
    }
  }

  const renderProductCard = (item: { size: string; id: string }, i: number) => {
    const product = productCatalogue[item.id]
    if (
      product?.__typename === "incontinenceProducts_Product" ||
      product?.__typename === "inserts_Product" ||
      product?.__typename === "otherCarersCenterProductType_Product"
    ) {
      const productImage =
        product.listingGallery && product.listingGallery[0]?.url
          ? product.listingGallery[0].url
          : placeholderImage

      return (
        <React.Fragment key={i}>
          <Col className="d-flex align-items-stretch">
            <Card className="mx-2 my-3 product-modal-card">
              <Card.Header className="bg-white position-relative">
                <Card.Img className="height-mobile-220" src={productImage} />
                {product.volume ? (
                  <Volume className="selector-volume" volume={product.volume} />
                ) : (
                  <></>
                )}
              </Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-center align-items-start flex-column">
                  <h5>
                    <b>
                      {product.title +
                        (product.volume ? ` ${product.volume}ml` : "")}
                    </b>
                  </h5>
                  <b
                    className={
                      selection.gender === "male"
                        ? "cormie-blue-text"
                        : "cormie-pink-text"
                    }
                  >
                    {product.variants?.[0]?.title &&
                    noSize.includes(product.variants?.[0]?.title)
                      ? "One Size"
                      : item.size}
                  </b>
                  <p>
                    {product.productsummary &&
                      product.productsummary.replace("<br />", "")}
                  </p>
                  {renderFeature(undefined, "Pack Size: " + product.packSize)}
                  {product.goodFor?.map((point, i) =>
                    renderFeature(i, point?.use),
                  )}
                </div>
              </Card.Body>
              <Card.Footer>
                <Row xs={1} sm={2} md={1} lg={2}>
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="mb-2 mb-sm-0 mb-md-2 mb-lg-0">
                      <span
                        className={
                          (isAlreadyAdded(item.id)
                            ? "opacity-50 pe-none "
                            : "") + "quantity minus"
                        }
                        data-testid={`minus${i}`}
                        onClick={() => {
                          if (
                            productQty[item.id] > 1 &&
                            !isAlreadyAdded(item.id)
                          ) {
                            setProductQty({
                              ...productQty,
                              [item.id]: productQty[item.id] - 1,
                            })
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </span>
                      <span
                        className={
                          (isAlreadyAdded(item.id) ? "opacity-50 " : "") +
                          "quantity number"
                        }
                      >
                        {isAlreadyAdded(item.id)
                          ? getSetProductCount(item.id)
                          : productQty[item.id]}
                      </span>
                      <span
                        className={
                          (isAlreadyAdded(item.id)
                            ? "opacity-50 pe-none "
                            : "") + "quantity plus"
                        }
                        data-testid={`plus${i}`}
                        onClick={() => {
                          if (!isAlreadyAdded(item.id)) {
                            setProductQty({
                              ...productQty,
                              [item.id]: (productQty[item.id] || 0) + 1,
                            })
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center">
                    <MainButton
                      className="m-0 w-100 px-0 text-nowrap max-width-210-prod-selector cart-button-hover"
                      onClick={() => {
                        if (
                          context === "preferences" &&
                          preferencesState &&
                          updatePreferencesState
                        ) {
                          if (isAlreadyAdded(item.id)) {
                            const itemIndex = preferencesState
                              .map((obj) => obj.id)
                              .indexOf(item.id)
                            const copiedPrefs = [...preferencesState]
                            copiedPrefs.splice(itemIndex, 1)
                            updatePreferencesState(copiedPrefs)
                            setProductQty({
                              ...productQty,
                              [item.id]: 0,
                            })
                          } else {
                            updatePreferencesState([
                              ...preferencesState,
                              {
                                id: item.id,
                                qty: productQty[item.id],
                              },
                            ])
                          }
                        } else {
                          if (isAlreadyAdded(item.id)) {
                            removeFromCart(item.id)
                            setProductQty({
                              ...productQty,
                              [item.id]: 0,
                            })
                          } else {
                            addToCart(item.id, productQty[item.id])
                          }
                        }
                      }}
                    >
                      {isAlreadyAdded(item.id) ? (
                        <>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="cart-hover-added"
                          />
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="cart-hover-remove"
                          />
                        </>
                      ) : (
                        <>
                          {"Add to " +
                            (context === "preferences" ? "order" : "cart")}
                        </>
                      )}
                    </MainButton>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </React.Fragment>
      )
    }
  }

  return (
    <>
      {children && (
        <>
          {/* Modal button */}
          <span
            onClick={() => {
              if (callback) {
                callback()
              }
              handleShow()
            }}
          >
            {children}
          </span>
        </>
      )}

      {/* Modal itself */}
      <Modal show={show} onHide={handleClose} dialogClassName={"modal80vw"}>
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Container className="px-4">
              {/* Modal Header */}
              <Row className="pt-4 position-sticky bg-white" style={{
                top: "0px",
                zIndex: 1020
              }}>
                <Col className="p-0" xs={8} sm={6}>
                  <Row className="align-items-center" xs={1} xl={2} >
                    <Col>
                      <h4 className="m-0">{"Add new product"}</h4>
                    </Col>
                    {selection?.gender && (
                      <Col>
                        <FontAwesomeIcon
                          icon={selection.gender === "male" ? faMars : faVenus}
                          className={
                            (selection.gender === "male"
                              ? "cormie-blue-text"
                              : "cormie-pink-text") + " me-2 half-muted-text"
                          }
                        />
                        <span
                          className={
                            (selection.gender === "male"
                              ? "cormie-blue-text"
                              : "cormie-pink-text") + " half-muted-text"
                          }
                        >
                          {`Products for ${
                            selection?.gender === "male" ? "men" : "women"
                          }`}
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col className="d-flex align-items-center p-0" xs={4} sm={6}>
                  <Row className="justify-content-end w-100 text-end">
                    {step > 1 && (
                      <Col
                        className="select-gender"
                        xs={3}
                        sm={6}
                        md={4}
                        xl={3}
                        xxl={2}
                      >
                        <a onClick={handleBack}>
                          <Row className="flex-row">
                            <Col className="text-start me-2 link-text">
                              <FontAwesomeIcon
                                icon={faChevronLeft}
                                className="me-2"
                              />
                              <u className="d-none d-sm-inline">{`${
                                step === 2 ? "Select Gender" : "Select Category"
                              }`}</u>
                            </Col>
                          </Row>
                        </a>
                      </Col>
                    )}
                    <Col
                      xs={3}
                      sm={6}
                      md={4}
                      xl={3}
                      xxl={2}
                      className="d-flex align-items-center select-gender"
                    >
                      <a onClick={handleClose}>
                        <u className="d-none d-sm-inline">{"CLOSE"}</u>
                        <FontAwesomeIcon icon={faX} className="ms-1" />
                      </a>
                    </Col>
                  </Row>
                </Col>
                <hr className="mt-2 mb-0"></hr>
              </Row>


              {/* Modal Body */}
              {/* Step 1: Gender selection */}
              {step === 1 && (
                <>
                  <Row>{["female", "male"].map(renderGenderCard)}</Row>
                </>
              )}

              {/* Step 2: Style selection */}
              {step === 2 && (
                <>
                  <Row xs={1} md={3}>
                    {selection.gender &&
                      productCategories[selection.gender].map(
                        renderCategoryCard,
                      )}
                  </Row>
                </>
              )}

              {/* Step 3: Product Selection */}
              {step === 3 && (
                <>
                  <Row xs={1} md={2} xl={3}>
                    {selection.gender && selection.style && (
                      <>
                        {selection.style === "other" ? (
                          <>{getOtherProducts().map(renderProductCard)}</>
                        ) : (
                          <>
                            {/* Render each product of the designated gender -> style */}
                            {productSizes[selection.gender][
                              selection.style
                            ].map(renderProductCard)}
                            {/* Render inserts if applicable */}
                            {(selection.style === "all-in-one" ||
                              selection.style === "pull-up pants") &&
                              productSizes[selection.gender][
                                insertMappings[selection.style]
                              ] &&
                              productSizes[selection.gender][
                                insertMappings[selection.style]
                              ].map(renderProductCard)}
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </>
              )}
            </Container>
          </>
        )}
      </Modal>
    </>
  )
}

export default ProductSelectorModal
