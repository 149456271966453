import { CountryVarType } from "../../apollo/vars/countryVar"
import { LocalUserQuery } from "../../generated/graphql"

const processCountryData = (data: LocalUserQuery): CountryVarType => {
  const returnObj: CountryVarType = {}

  data.countries?.forEach((country) => {
    if (country?.name && country?.iso && country?.states) {
      const countryIso = country.iso
      returnObj[countryIso] = {
        name: country.name,
        iso: country.iso,
        states: {},
      }

      country.states.forEach((state) => {
        if (state?.name && state?.abbreviation) {
          returnObj[countryIso].states[state.abbreviation] = {
            name: state.name,
            abbreviation: state.abbreviation,
          }
        }
      })
    }
  })

  return returnObj
}

export default processCountryData
