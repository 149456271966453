import { faDownload, faSquareFull } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import isCarerVar from "../apollo/vars/isCarerVar"
import Loading from "../components/Loading"
import MainButton from "../components/MainButton"
import OrderItemTable from "../components/OrderItemTable"
import { orderStatusArray } from "../components/OrdersTable"
import PageTitle from "../components/PageTitle"
import UserInformation from "../components/UserInformation"
import {
  useCancelOrderMutation,
  useOrderCancelledQuery,
  useClientOrderQuery,
} from "../generated/graphql"
import CormiePageContainer from "../layouts/CormiePageContainer"
import CormieSection from "../layouts/CormieSection"
import CormieSectionTitle from "../layouts/CormieSectionTitle"
import { useIdleTimer } from "react-idle-timer"
import OrganisationInformation from "../components/OrganisationInformation"
import OrderInformation from "../components/OrderInformation"
import isAdminVar from "../apollo/vars/isAdminVar"
import organisationIdVar from "../apollo/vars/organisationIdVar"
import GenericBoolModal from "../components/GenericBoolModal"

const SingleOrder: React.FC = () => {
  const isCarer = isCarerVar()
  const isAdmin = isAdminVar()
  const organisationId = organisationIdVar()
  const location = useLocation()

  const splitPath = location.pathname.split("/")
  const uid = splitPath[splitPath.findIndex((val) => val === "orders") + 1]
  let orderReceiptUrl = ""

  const [showModal, setShowModal] = useState(false)

  const { data, loading } = useClientOrderQuery({
    variables: {
      id: uid,
      allOrganisations: isAdmin,
      organisationId: isAdmin ? "" : organisationId,
    },
  })

  if (typeof data?.order?.pdfUrl === "string") {
    orderReceiptUrl = data.order.pdfUrl
  }

  const [cancelOrder, { loading: cancelLoading, error: cancelError }] =
    useCancelOrderMutation()

  const { refetch, startPolling, stopPolling } = useOrderCancelledQuery({
    variables: {
      id: uid,
      allOrganisations: isAdmin,
      organisationId: isAdmin ? "" : organisationId,
    },
  })
  startPolling(5000) // 5 seconds
  const onIdle = () => {
    stopPolling()
  }
  const onActive = () => {
    refetch()
    startPolling(5000) // 5 seconds
  }
  useIdleTimer({
    onIdle,
    onActive,
    timeout: 30000, // thirty seconds
  })

  return (
    <>
      <GenericBoolModal
        showGenericBoolModal={showModal}
        onHide={() => {
          setShowModal(false)
        }}
      >
        <div className="mt-3 mx-3">
          <h5 className="mb-3">
            <b>
              {"Are you sure you want to cancel this order?"}
            </b>
          </h5>
          <Row xs={1} sm={2} className="mt-4">
            <Col className="w-auto">
              <MainButton
                className="m-0"
                onClick={() => {
                  if (data?.order?.id && !cancelError) {
                    cancelOrder({
                      variables: {
                        id: data.order.id,
                        refund: true,
                      },
                    })
                  }
                  setShowModal(false)
                }}
              >
                <b className="px-2">{"YES"}</b>
              </MainButton>
            </Col>
            <Col className="d-flex align-items-center ps-0 ps-sm-5 pt-4 pt-sm-0">
              <a
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <b>
                  <u>{"NO"}</u>
                </b>
              </a>
            </Col>
          </Row>
        </div>
      </GenericBoolModal>

      <CormiePageContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row xs={1} sm={2}>
              <Col>
                <PageTitle className="mb-3">
                  {`Order #${data?.order?.reference}`}
                </PageTitle>
              </Col>
              <Col>
                <div className="mt-sm-5 mb-3 d-flex justify-content-start justify-content-sm-end align-items-center">
                  <span className="h5 my-0 me-2 opacity-75">
                    {"Order status:"}
                  </span>
                  <MainButton
                    color={
                      orderStatusArray[
                      data?.order
                        ?.shippitStatus as keyof typeof orderStatusArray
                      ] === "Cancelled"
                        ? "pink"
                        : "blue"
                    }
                    className="m-0"
                    faded={!data?.order?.trackingCode}
                    nonReactive={!data?.order?.trackingCode}
                    onClick={() => {
                      if (data?.order?.trackingCode)
                      // && data.order.status != cancelled
                        window.open(
                          `https://app.shippit.com/tracking/${data.order.trackingCode}`,
                          "_blank",
                        )
                    }}
                  >
                    <div
                      className={data?.order?.trackingCode ? "" : "opacity-50"}
                    >
                      {data?.order?.shippitStatus
                        ? orderStatusArray[
                          data?.order
                            ?.shippitStatus as keyof typeof orderStatusArray
                        ]
                          ? orderStatusArray[
                            data?.order
                              ?.shippitStatus as keyof typeof orderStatusArray
                          ]
                          : "In transit"
                        : "Ordered"}
                    </div>
                  </MainButton>
                </div>
              </Col>
            </Row>
            <Row xs={1} md={2} lg={1} xl={2}>
              <Col>
                <CormieSection left>
                  <CormieSectionTitle>
                    {`${isCarer || isAdmin ? "Customer" : "Your"} information`}
                  </CormieSectionTitle>
                  <UserInformation
                    isOrderPage
                    user={data?.order?.customer?.user}
                  />
                </CormieSection>
              </Col>

              <Col>
                <CormieSection right>
                  <CormieSectionTitle>
                    {"Organisation information"}
                  </CormieSectionTitle>
                  <OrganisationInformation order={data?.order} />
                </CormieSection>

                <CormieSection right>
                  <CormieSectionTitle>{"Order information"}</CormieSectionTitle>
                  <OrderInformation order={data?.order} />
                </CormieSection>

                <CormieSection right>
                  <CormieSectionTitle>
                    {"Delivery instructions"}
                  </CormieSectionTitle>
                  {data?.order?.optionalDeliveryInstructions ? (
                    <>{data?.order?.optionalDeliveryInstructions}</>
                  ) : data?.order?.customer?.user?.deliveryInstructions ? (
                    <>{data?.order?.customer?.user?.deliveryInstructions}</>
                  ) : (
                    "No special delivery instructions provided"
                  )}
                </CormieSection>
              </Col>
            </Row>

            <Row>
              <Col>
                <PageTitle>{"Order details"}</PageTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <CormieSection>
                  <OrderItemTable order={data?.order} />
                </CormieSection>
              </Col>
            </Row>
            {orderReceiptUrl != "" && (
              <Row className="mb-5 mt-3">
                <Col className="d-flex align-items-center">
                  <a target="_blank" href={orderReceiptUrl} rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faDownload}
                      mask={faSquareFull}
                      className="red-blue-gradient-icon me-2"
                      inverse
                    />
                    <u className="cormie-blue-text">{"Download Receipt"}</u>
                  </a>
                </Col>
                {data?.order?.cancellable === 1 && (
                  <Col className="d-flex justify-content-end">
                    <MainButton
                      className="mb-0"
                      onClick={() => {
                        setShowModal(true)
                      }}
                      nonReactive={cancelLoading || !!cancelError}
                      disabled={cancelLoading || !!cancelError}
                      faded={!!cancelError}
                    >
                      {cancelLoading ? (
                        <Loading />
                      ) : cancelError ? (
                        "Error occurred"
                      ) : (
                        "Cancel"
                      )}
                    </MainButton>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </CormiePageContainer>
    </>
  )
}

export default SingleOrder
