import React, { useEffect, useState } from "react"
import { Row } from "react-bootstrap"
import SearchBar from "../components/SearchBar"
import SearchTags from "../components/SearchTags"
import OrdersTable, { OrderSortType } from "../components/OrdersTable"
import { useIdleTimer } from "react-idle-timer"
import {
  useOrdersCancelledQuery,
  usePaginatedOrdersQuery,
  PaginatedOrdersQueryVariables,
  useAllRouteTagsQuery,
} from "../generated/graphql"
import CormieSection from "../layouts/CormieSection"
import removeTrailingBackslashes from "../utils/general/removeTrailingBackslashes"
import isAdminVar from "../apollo/vars/isAdminVar"
import ToggleAdminLevel from "../components/ToggleAdminLevel"
import organisationIdVar from "../apollo/vars/organisationIdVar"
import { useReactiveVar } from "@apollo/client"
import allOrgsVar from "../apollo/vars/allOrgsVar"

const OrdersInterface: React.FC<{
  isSearchable?: boolean
  pollsForCancellable?: boolean
  clientId?: string
  carerId?: string
}> = ({ isSearchable, pollsForCancellable, clientId, carerId }) => {
  const isAdmin = isAdminVar()
  const organisationId = organisationIdVar()
  const allOrgs = useReactiveVar(allOrgsVar)

  const [pageState, setPageState] = useState({ show: 25, pageNo: 1, max: 25 })
  const [searchTerm, setSearchTerm] = useState("")
  const [localTags, setLocalTags] = useState<Record<string, boolean>>({})
  const [orderSort, setOrderSort] = useState<OrderSortType>({
    active: true,
    ascending: false,
    field: "date",
  })

  const selectedTags: string[] = []
  for (const [key, value] of Object.entries(localTags)) {
    if (value) {
      selectedTags.push(key)
    }
  }

  const orderQueryVariables: PaginatedOrdersQueryVariables = {
    limit: pageState.show,
    offset: pageState.show * pageState.pageNo - pageState.show,
    allOrganisations: allOrgs,
    organisationId: allOrgs ? "" : organisationId,
    carerId: carerId,
    clientId: clientId,
    search: searchTerm,
    routeTags: selectedTags,
    sortField: orderSort.field,
    sortAscending: orderSort.ascending,
  }

  const { refetch, startPolling, stopPolling } = useOrdersCancelledQuery({
    variables: orderQueryVariables,
  })

  if (pollsForCancellable) {
    startPolling(5000) // 5 seconds
    const onIdle = () => {
      stopPolling()
    }
    const onActive = () => {
      refetch()
      startPolling(5000) // 5 seconds
    }
    useIdleTimer({
      onIdle,
      onActive,
      timeout: 30000, // thirty seconds
    })
  }

  useAllRouteTagsQuery({
    onCompleted: (data) => {
      const tagObj = localTags
      data.getAllRouteTagNamesInPool.forEach((tag) => {
        if (tag && !Object.keys(localTags).includes(tag)) {
          tagObj[tag] = false
        }
      })
      setLocalTags({ ...tagObj })
    },
  })

  const { data: allOrgOrdersData, loading: allOrgOrdersLoading } =
    usePaginatedOrdersQuery({
      variables: orderQueryVariables,
      onCompleted: (data) => {
        if (data.orderCount) {
          setPageState({
            ...pageState,
            max: data.orderCount,
          })
        }
      },
    })

  useEffect(() => {
    setPageState({
      ...pageState,
      pageNo: 1,
      max: allOrgOrdersData?.orderCount || 25,
    })
  }, [searchTerm, localTags])

  // Update local state when the search input changes
  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let str = e.target.value
    if (str.charAt(str.length - 1) === "\\") {
      str = removeTrailingBackslashes(str)
    }
    setSearchTerm(str)
  }

  return (
    <>
      <Row>
        <CormieSection>
          {isSearchable && (
            <>
              {/* Search Bar component */}
              <SearchBar
                onChange={onSearchInputChange}
                placeholder="Start typing a customer name, order #, status or total"
              />
              {/* Search Tags component */}
              <SearchTags tags={localTags} setTags={setLocalTags} />
            </>
          )}
          {isAdmin && !clientId && !carerId && (
            <>
              {/* Admin filter by org toggle */}
              <ToggleAdminLevel
                ignoreOrgPrompt={"Show only orders from my organisation"}
              />
            </>
          )}
          {/* Order table */}
          <Row className="my-1 flex-column px-sm-3">
            <OrdersTable
              ordersData={allOrgOrdersData?.orders}
              ordersLoading={allOrgOrdersLoading}
              pageState={pageState}
              setPageState={setPageState}
              orderSort={orderSort}
              setOrderSort={setOrderSort}
              withTags
              linkNames
            />
          </Row>
        </CormieSection>
      </Row>
    </>
  )
}

export default OrdersInterface
