import React from "react"
import { Row, Col } from "react-bootstrap"
import Toggle from "react-toggle"
import allOrgsVar from "../apollo/vars/allOrgsVar"

const ToggleAdminLevel: React.FC<{
  ignoreOrgPrompt: string
}> = ({ ignoreOrgPrompt }) => {
  const allOrgs = allOrgsVar()

  return (
    <Row className="px-sm-3 flex-column flex-md-row" xs={1}>
      <Col className="d-flex align-items-center col-auto mb-3 mb-md-0 w-100 justify-content-end">
        <span className="me-3 text-end">{ignoreOrgPrompt}</span>
        <Toggle
          checked={!allOrgs}
          aria-label="Ignore my organisation toggle"
          readOnly
          onClick={() => allOrgsVar(!allOrgs)}
        />
      </Col>
    </Row>
  )
}

export default ToggleAdminLevel
