import allOrgsVar from "../../apollo/vars/allOrgsVar"
import isAdminVar from "../../apollo/vars/isAdminVar"
import isCarerVar from "../../apollo/vars/isCarerVar"
import isClientVar from "../../apollo/vars/isClientVar"

const parseGroups = (groups: (string | null)[]) => {
  if (groups) {
    groups.forEach((group) => {
      switch (group) {
      case "Carers Centre - Carers":
        isCarerVar(true)
        break
      case "Carers Centre - Clients":
        isClientVar(true)
        break
      case "Carers Centre - Admins":
        isAdminVar(true)
        allOrgsVar(true)
        break
      }
    })
  }
}

export default parseGroups
